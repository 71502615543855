<template lang="pug">
.lp-card
  .lp-card__container
    .lp-card__illustration
      IllustrationSvg.lp-card__svg
    .lp-card__content
      h3.lp-card__title
        Icon.lp-card__icon
        template {{ $t('pages.ssr_landing.monetize.title') }}
      p.lp-card__text(
        v-html="$t('pages.ssr_landing.monetize.text')"
      )
</template>

<script>
import Icon from './svg/Icon'
import IllustrationSvg from './svg/Illustration'

export default {
  components: {
    Icon,
    IllustrationSvg
  }
}
</script>

<style lang="scss" scoped>
.lp-card {
  &__container {
    flex-direction: row-reverse;

    @include display-less(tablet) {
      flex-direction: column-reverse;
    }
  }
}
</style>
