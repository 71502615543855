<template lang="pug">
.lp-card
  .lp-card__container
    .lp-card__illustration
      IllustrationSvg.lp-card__svg
    .lp-card__content
      h3.lp-card__title
        Icon.lp-card__icon
        template {{ $t('pages.ssr_landing.manage.title') }}
      p.lp-card__text(
        v-html="$t('pages.ssr_landing.manage.text')"
      )
</template>

<script>
import Icon from './svg/Icon.vue'
import IllustrationSvg from './svg/Illustration'

export default {
  components: {
    Icon,
    IllustrationSvg
  }
}
</script>

<style lang="scss" scoped>
</style>
